import React from 'react'
import styled from 'styled-components/macro'
import media from '../styles/media'
import { IoIosArrowForward as IconCaretRight } from 'react-icons/io'

const Container = styled.div`
  color: white;
  font-size: 0.85rem;
  font-family: var(--font-heading);
  font-weight: 400;
  display: flex;
  align-items: center;

  ${media.tablet`
    font-size: 0.95rem;
  `};

  svg {
    color: white;
    opacity: 0.75;
    margin: 0 0.75rem;
  }
`

const Breadcrumbs = ({ pageContext }) => {
  return (
    <Container>
      {pageContext.section && (
        <>
          <IconCaretRight />
          {pageContext.section}
        </>
      )}

      {pageContext.title && (
        <>
          <IconCaretRight />
          {pageContext.title}
        </>
      )}
    </Container>
  )
}

export default Breadcrumbs
