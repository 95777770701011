import styled from 'styled-components'
import media from '../styles/media'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 3rem;
  max-width: var(--container-max-width);
  max-width: ${props => props.medium && 'var(--container-max-width-medium)'};
  max-width: ${props => props.content && 'var(--container-max-width-content)'};
  margin: ${props => props.content && '3rem auto'};

  ${media.tablet`
    margin: ${props => props.content && '5rem auto'};
  `};
`

export default Container
