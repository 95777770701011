import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import Container from './Container'
import Breadcrumbs from './Breadcrumbs'
import Logo from './Logo'
import media from '../styles/media'

const Nav = styled(Container)`
  ul {
    display: flex;
  }

  li {
    display: flex;
    margin-left: 1em;
    &:first-child {
      position: relative;
      margin: 0;
    }
  }

  a {
    text-decoration: none;
    transition: all 0.2s;
    color: var(--color-text-light);
  }
`

const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
  background: none;
  position: absolute;
  height: var(--header-height-large);

  ${Nav} {
    ul {
      ${props =>
        props.location === '/'
          ? 'justify-content: space-between'
          : css`
              justify-content: center;

              ${media.tablet`
              justify-content: space-between;
            `};
            `}
    }
  }
`

const activeLinkStyle = {
  color: 'white',
}

const Menu = ({ location, pageContext }) => {
  return (
    <Header location={location && location.pathname}>
      <Nav as="nav">
        <ul>
          <li>
            <Link to="/" activeStyle={activeLinkStyle}>
              <Logo
                location={location && location.pathname}
                logoColour={pageContext && pageContext.logoColour}
              />
            </Link>

            {location && location.pathname !== '/' && (
              <Breadcrumbs pageContext={pageContext} />
            )}
          </li>
          {/* <li>
            <Link to="/about/" activeStyle={activeLinkStyle}>
              About
            </Link>
          </li>
          <li>
            <Link to="/contact/" activeStyle={activeLinkStyle}>
              Contact
            </Link>
          </li> */}
        </ul>
      </Nav>
    </Header>
  )
}

export default Menu
