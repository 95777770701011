import { createGlobalStyle } from 'styled-components'
import media from './media'
import { shadeColor } from '../utils/colorShades'

const GlobalStyle = createGlobalStyle`
  /* https://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* CSS Variables */

  :root {

    /*  
    ------------------
      Typography
    ------------------
    */

    --font-primary: 'Merriweather', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif;
    --font-secondary: serif;
    --font-heading: 'Merriweather Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif;
    /* --font-logo: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif; */

    /* set base values */
    --text-base-size: 1em;
    --text-scale-ratio: 1.2;

    /* type scale */
    --text-xs: calc(1em / (var(--text-scale-ratio) * var(--text-scale-ratio)));
    --text-sm: calc(1em / var(--text-scale-ratio));
    --text-md: calc(1em * var(--text-scale-ratio));
    --text-lg: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio));
    --text-xl: calc(
      1em * var(--text-scale-ratio) * var(--text-scale-ratio) *
        var(--text-scale-ratio)
    );
    --text-xxl: calc(
      1em * var(--text-scale-ratio) * var(--text-scale-ratio) *
        var(--text-scale-ratio) * var(--text-scale-ratio)
    );
    --text-xxxl: calc(
      1em * var(--text-scale-ratio) * var(--text-scale-ratio) *
        var(--text-scale-ratio) * var(--text-scale-ratio) *
        var(--text-scale-ratio)
    );

    /* line-height */
    --heading-line-height: 1.2;
    --body-line-height: 1.4;

    ${media.tablet`
      --space-unit: 1.25em;
      --component-padding: var(--space-md);
    `};

    ${media.desktop`
      --text-base-size: 1.1em;
      --text-scale-ratio: 1.1;
    `};


    /*  
    ------------------
      Colours
    ------------------
    */

    /* main colors */
    --color-primary: #4d84f4;
    /* --color-primary-light: color-mod(var(--color-primary) tint(15%)); */
    --color-primary-light: #95b6f8;
    /* --color-primary-dark: color-mod(var(--color-primary) shade(15%)); */
    --color-primary-dark: #0e55e6;
    --color-primary-bg: color-mod(var(--color-primary) alpha(20%));

    --color-accent: #f5414f;
    --color-accent-light: color-mod(var(--color-accent) tint(15%));
    --color-accent-dark: color-mod(var(--color-accent) shade(10%));
    --color-accent-bg: color-mod(var(--color-accent) alpha(20%));

    --black: #1d1d21;
    --gray-10: #2e2e31;
    --gray-8: #575757;
    --gray-6: #7b7a7d;
    --gray-4: #a5a5a6;
    --gray-3: #bbbbbc;
    --gray-2: #d1d0d2;
    --gray-1: #e8e7e8;
    --white: white;

    /* feedback */
    --color-success: #88c459;
    --color-error: #f5414f;
    --color-warning: #ffd137;

    /* typography */
    --color-text: var(--gray-8);
    --color-text-light: #aaacae;
    --color-text-heading: var(--gray-10);
    --color-text-heading-light: #ffffff;
    --color-text-subtle: var(--gray-6);
    --color-link: var(--color-primary);
    --color-link: #ffffff;
    --color-link-visited: var(--color-primary-dark);
    --color-mark: var(--color-accent-bg);
    --color-blockquote-border: var(--gray-2);

    /* border */
    --color-border: var(--gray-2);

    /* body */
    --color-body: #fff;
    --color-body-dark: #141414;
    --color-body-dark-alt: ${shadeColor('#141414', 28)};


    /* Body - Hero animation */
    --color-hero-1: ${shadeColor('#141414', 0)};
    --color-hero-2: ${shadeColor('#141414', 10)};
    --color-hero-3: ${shadeColor('#141414', -4)};
    --color-hero-4: ${shadeColor('#141414', 7)};
    --color-hero-5: ${shadeColor('#141414', 21)};
    --color-hero-6: ${shadeColor('#141414', 14)};
    --color-hero-7: ${shadeColor('#141414', 0)};

    /* forms */
    --form-element-border: var(--color-border);
    --form-element-border-focus: var(--color-primary);
    --form-element-border-error: var(--color-error);
    --form-element-bg: var(--white);
    --form-text-placeholder: var(--gray-4);

    /* buttons */
    --btn-primary-bg: var(--color-primary);
    --btn-primary-hover: var(--color-primary-dark);
    --btn-primary-active: var(--color-primary-dark);
    --btn-primary-label: var(--white);

    /* icons */
    --color-icon-primary: var(--gray-4);
    --color-icon-secondary: inherit;


    /*  
    ------------------
      Spacing
    ------------------
    */

    /* spacing values */
    --space-unit: 1em;
    --space-xxxs: calc(0.25 * var(--space-unit));
    --space-xxs: calc(0.375 * var(--space-unit));
    --space-xs: calc(0.5 * var(--space-unit));
    --space-sm: calc(0.75 * var(--space-unit));
    --space-md: calc(1.25 * var(--space-unit));
    --space-lg: calc(2 * var(--space-unit));
    --space-xl: calc(3.25 * var(--space-unit));
    --space-xxl: calc(5.25 * var(--space-unit));
    --space-xxxl: calc(8.5 * var(--space-unit));

    /* components padding */
    --component-padding: var(--space-sm);

    /* grid gap */
    --grid-gap: 20px;

    /* element dimensions */
    --header-height: 72px;
    --header-height-large: 120px;
    --header-height-phone: 56px;
    --sidebar-width: 90px;
    --sidebar-width-tablet: 72px;

    /* max-width */
    --container-max-width: 1280px;
    --container-max-width-medium: 1140px;
    --container-max-width-content: 1024px;

    ${media.tablet`
      --space-unit: 1.25em;
      --component-padding: var(--space-md);
    `};


    /*  
    ------------------
      Buttons
    ------------------
    */

    --btn-font-size: 1em;
    --btn-sm: calc(var(--btn-font-size) - 0.2em);
    --btn-md: calc(var(--btn-font-size) + 0.2em);
    --btn-lg: calc(var(--btn-font-size) + 0.4em);

    --btn-radius: var(--radius);


    /*  
    ------------------
      Styling
    ------------------
    */

    --box-shadow-container: 0 5px 15px rgba(0,0,0,.05), 0 -2px 60px rgba(0,0,0,.05);
    --box-shadow-container-light: 0 5px 15px rgba(0,0,0,.03), 0 -2px 60px rgba(0,0,0,.03);
    --border-radius: 4px;
  }



  /* Added to Fix Footer to bottom of viewport */
  html, body {
    width: 100%;
    height: 100%;
  }
  .siteRoot {
    display: flex;
    flex-direction: column;
  }
  .siteContent {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
  footer {
    width: 100%;
  }

  /* End Fix to Place Footer on Bottom of Viewport */

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  @media screen and (min-width: 35em) {
    html {
      margin-right: calc(-100vw + 100%);
      overflow-x: hidden;
    }
  }

  ol, ul, li {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  body {
    background: var(--color-body-dark);
    line-height: 1;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    font-weight: 400;
    font-size: var(--text-base-size);
    font-family: var(--font-primary);
    color: var(--color-text);

  }

  img {
    display: block;
  	width: 100%;
  	height: auto;
  }

  button,
  input {
    font-family: inherit;
    font-size: inherit;
    background: none;
    border: none;
    outline: none;
    appearance: none;
    border-radius: 0;
    resize: none;
    &:focus {
      outline: none;
    }
    &:invalid {
      box-shadow: none;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  form legend {
    font-family: var(--font-heading);
    color: var(--color-text-heading);
    margin-top: 0;
    margin-bottom: var(--space-xxs);
    line-height: var(--heading-line-height);
    letter-spacing: -1.7px;
  }

  input,
  textarea,
  select,
  button {
    font-size: var(--text-base-size);
    font-family: var(--font-primary);
    color: var(--color-text);
  }

  p {
    line-height: var(--body-line-height);
  }

  a {
    color: var(--color-link);

    &:visited {
      /* color: var(--color-link-visited); */
    }
  }

  b,
  strong {
    font-weight: bold;
  }


  /*  
  ------------------
    Spacing
  ------------------
  */

  /* vertical margins */
  .margin-top,
  .margin-top--md {
    margin-top: var(--space-md);
  }

  .margin-top--sm {
    margin-top: var(--space-sm);
  }

  .margin-top--lg {
    margin-top: var(--space-lg);
  }

  .margin-top--xl {
    margin-top: var(--space-xl);
  }

  .margin-top--xxl {
    margin-top: var(--space-xxl);
  }

  .margin-bottom,
  .margin-bottom--md {
    margin-bottom: var(--space-md);
  }

  .margin-bottom--sm {
    margin-bottom: var(--space-sm);
  }

  .margin-bottom--lg {
    margin-bottom: var(--space-lg);
  }

  .margin-bottom--xl {
    margin-bottom: var(--space-xl);
  }

  .margin-bottom--xxl {
    margin-bottom: var(--space-xxl);
  }

  ${media.tablet`
    .margin-top, .margin-top--md {
        margin-top: var(--space-lg);
    }
    
    .margin-top--sm {
        margin-top: var(--space-md);
    }
    
    .margin-top--lg {
        margin-top: var(--space-xl);
    }
    
    .margin-top--xl {
        margin-top: var(--space-xxl);
    }
    
    .margin-top--xxl {
        margin-top: var(--space-xxxl);
    }

    .margin-bottom, .margin-bottom--md {
        margin-bottom: var(--space-lg);
    }
    
    .margin-bottom--sm {
        margin-bottom: var(--space-md);
    }
    
    .margin-bottom--lg {
        margin-bottom: var(--space-xl);
    }
    
    .margin-bottom--xl {
        margin-bottom: var(--space-xxl);
    }
    
    .margin-bottom--xxl {
        margin-bottom: var(--space-xxxl);
    }
  `};
`
export default GlobalStyle
