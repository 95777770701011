import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import media from '../styles/media'

const Wrapper = styled.footer`
  position: relative;
  z-index: 1;
  background-color: var(--color-body-dark);
  min-height: 10rem;
  display: flex;
  align-items: center;
  padding: 1.5rem 0;

  ${Container} {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    a {
      width: 100%;
      font-size: 0.85rem;
      text-decoration: none;
      font-family: var(--font-heading);
      font-weight: 700;
      display: inline-flex;
      justify-content: center;
      opacity: 0.75;
      transition: all 0.5s;
      padding: 1rem;
      margin: 0;

      ${media.tablet`
        width: auto;
        padding: 1rem 3rem 1rem 0;
      `};

      &:hover {
        opacity: 1;
        text-decoration: underline;
      }
    }
  }
`

const Footer = () => (
  <Wrapper>
    <Container>
      <a
        href="https://www.linkedin.com/in/dparrelli"
        target="_blank"
        rel="noopener noreferrer"
      >
        LinkedIn
      </a>
      <a
        href="https://twitter.com/dparrelli"
        target="_blank"
        rel="noopener noreferrer"
      >
        Twitter
      </a>
      <a
        href="https://wrapbootstrap.com/user/dparrelli"
        target="_blank"
        rel="noopener noreferrer"
      >
        WrapBootstrap
      </a>
      <a
        href="https://themeforest.net/user/daveeeo/portfolio"
        target="_blank"
        rel="noopener noreferrer"
      >
        Themeforest
      </a>
      <a
        href="mailto:hello@davidparrelli.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        hello@davidparrelli.com
      </a>
    </Container>
  </Wrapper>
)

export default Footer
