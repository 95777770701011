import React from 'react'
import styled from 'styled-components/macro'
import media from '../styles/media'

const Container = styled.svg`
  width: 38px;
  height: 38px;

  ${media.tablet`
    width: 52px;
    height: 52px;
  `};

  circle {
    fill: ${props => props.logoColour || '#537cf3'};
  }

  text {
    fill: ${props =>
      props.logoColour === '#ffffff' ? 'var(--color-primary)' : '#ffffff'};
    font-family: 'Baloo';
    font-size: 140px;
  }
`

const Logo = props => (
  <Container
    id="text"
    xmlns="https://www.w3.org/2000/svg"
    width="512"
    height="512"
    viewBox="0 0 512 512"
    {...props}
  >
    <circle id="background" className="cls-1" cx="256" cy="256" r="256" />
    <text
      id="P"
      className="cls-2"
      transform="translate(206.363 338.507) scale(2.281)"
    >
      P
    </text>
    <text
      id="P-2"
      data-name="P"
      className="cls-2"
      transform="matrix(-2.281, 0, 0, -2.281, 305.028, 173.493)"
    >
      P
    </text>
  </Container>
)

export default Logo
