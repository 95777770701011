import React from 'react'
import styled, { ThemeProvider, css } from 'styled-components'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.ico'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import config from '../utils/siteConfig'
import Menu from '../components/Menu'
import Footer from '../components/Footer'

const SiteContent = styled.div`
  ${props =>
    props.location && props.location === '/'
      ? css`
          background-color: var(--color-body-dark);
          color: var(--color-text-light);

          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          form legend {
            color: var(--color-text-heading-light);
          }

          p,
          a {
            color: var(--color-text-light);
          }
        `
      : css`
          background-color: var(--color-body);
        `};
`

const Template = ({ children, location, pageContext }) => {
  return (
    <div className="siteRoot">
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={favicon} />
      </Helmet>

      <ThemeProvider theme={theme}>
        <>
          <SiteContent
            location={location && location.pathname}
            className="siteContent"
          >
            <Menu location={location} pageContext={pageContext} />
            {children}
          </SiteContent>
          <Footer />
        </>
      </ThemeProvider>
      <GlobalStyle />
    </div>
  )
}

export default Template
